import React, { useState } from 'react'

const withContainer = Component => ({ initial_toggle }) => {
  const [toggle, setToggle] = useState(initial_toggle)

  const mergedProps = {
    toggle,
    setToggle
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
