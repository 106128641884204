import React, { useCallback } from 'react';

import { FormContent, SearchActionButton, SearchForm, SearchFrame, SearchTable, TableActions, TableHeader, TableRow } from '@front/squirtle';
import { hooks, I18n } from '@front/volcanion'
import OrderCallbacks from '@abra/panels/OrderSearchForm/callbacks';

import { Box } from '@mui/material'

import ButtonGlobalActions from './ButtonGlobal'
import ButtonTableActions from './ButtonTable'
import Callbacks from './callbacks'
import Form from './Form'
import Row from './Row'
import Header from './Header'
import Popup from './Popup'

const OrderList = props => {
  const search_params = hooks.useSearchParams()
  const [codeIntlPhone] = hooks.useSetting('code_international_phone')

  const filterToForm = useCallback(Callbacks.filterToFormHandler(search_params), [search_params])
  const formToFilter = useCallback(OrderCallbacks.formToFilterHandler(codeIntlPhone), [codeIntlPhone])

  return <>
    <Box className={'safearea'}>
      <Popup />
      <SearchFrame
        model_name={'order'}
        populate={[
          'payment_type',
          'channel',
          'job.payment',
          'source.poi',
          'destination.poi',
          'commercial_package',
          'do.commercial_formula',
          'do.customerinfo',
          'do.auth.application',
          'do.info',
          'assigned_transport.info',
          'current_target.eta',
          'loads',
          'service.contract.company',
          'service.contract.formula',
          'info'
        ]}
        sort={['requestedAt DESC']}
        createButtonProps={{
          label: I18n.t('order.add'),
          size: 'small'
        }}
      >
        <SearchActionButton> <ButtonGlobalActions model_name={'order'} /></SearchActionButton>
        <SearchForm
          submitOnMount
          filterToForm={filterToForm}
          formToFilter={formToFilter}
          _controlsButton={{ size: 'small' }}
        >
          <FormContent><Form initial_toggle={!!search_params} /></FormContent>
        </SearchForm>
        <SearchTable
          formProps={{ onRowClick: null }}
          tableProps={{
            placementPagination: 'both',
            enableGlobalActions: true,
            enableRowSelection: true,
          }}
        >
          <TableHeader><Header /></TableHeader>
          <TableRow><Row /></TableRow>
          <TableActions><ButtonTableActions /></TableActions>
        </SearchTable>
      </SearchFrame>
    </Box>
  </>
}

export default React.memo(OrderList)
