class Callbacks {
  static filterToFormHandler(search_params) {
    return function filterToForm(filter) {
      const driver_id = _.head(_.get(search_params, 'driver_id'))
      const operator_id = _.head(_.get(search_params, 'operator_id'))
      const client_phone = _.head(_.get(search_params, 'client_phone'))
      return _.merge({},
        filter,
        !!driver_id && { assigned_transport: { driver: driver_id } },
        !!operator_id && { operator: operator_id },
        !!client_phone && { do: { auth: { gsm: `+${client_phone}` } } }
      )
    }
  }
}

export default Callbacks
